import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
} from "@material-ui/core/";
import { QRCodeSVG } from 'qrcode.react';

import * as colors from "../../../theme/colors";
import TextButton from "../../text-button/text-button";
import { defaultProgramColors } from '../../../utils/programRoleLabels';
import { PROGRAM_TYPES } from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  dialogContent: {},
  title: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: "bold",
    margin: "10px 0",
  },
  clubCode: {
    color: (props) => props.codeColor,
    fontSize: 42,
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.up('sm')]: {
      fontSize: 60,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 100,
    }
  },
  copyBtn: {
    marginLeft: 5,
    color: colors.darkThemeBlueGray,
    fontWeight: "bold",
  },
  copyArea: {
    opacity: 0,
    width: 1,
    height: 1,
  },
  copyBtnBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  qrCode: {
    display: "flex",
    justifyContent: "center"
  }
}));

const getColor = (programTypeDetails) => {
  return programTypeDetails?.programType === PROGRAM_TYPES.COLLEGE_LOOP ? colors.darkBlue100 : programTypeDetails?.backgroundColor || defaultProgramColors.backgroundColor;
};

export const ShareProgramCodeSection = ({ onBack, clubCode, programTypeDetails }) => {
  const codeColor = getColor(programTypeDetails);
  const classes = useStyles({ codeColor });

  const [isCopied, setIsCopied] = useState(false);

  return (
    <>
      <Typography id="dialog-description" className={classes.title}>Share Your Club Code</Typography>

      <Typography role={"article"} className={classes.clubCode} aria-live={"assertive"} aria-label={clubCode}>
        {clubCode}
      </Typography>

      <Box>
        <Box className={classes.qrCode}>
          <QRCodeSVG size={300} value={`${window._env_.REACT_APP_API_URL}/join-program/qrCodeInvitation/${clubCode}`}/>
        </Box>
        <Box className={classes.copyBtnBox}/>
      </Box>
      <Box className={classes.buttonBox}>
        <TextButton onClick={() => onBack()}>Back</TextButton>
        <Button
          className={classes.copyBtn}
          onClick={(e) => {
            navigator.clipboard.writeText(clubCode);
            e.target.focus();
            setIsCopied(true);
          }}
          aria-live="polite"
        >
          {isCopied ? "Copied" : "Copy"}
        </Button>
      </Box>
    </>
  );
};
