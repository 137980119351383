import React, { useState, useEffect } from "react";

import {connectTo, createMarkup} from "../../utils/generic";

import queryString from "query-string";
import "./project.scss";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { push as pushToUrl } from "connected-react-router";
import { updateProject } from "../../api/galleryProjects";

import * as calendarActions from "../../actions/calendar";
import * as notificationActions from "../../actions/notification";

import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Link } from "react-router-dom";

import { getGalleryProjectById } from "../../api/galleryProjects";

import CustomButton from "../../components/customButton/customButton";
import TextCustomButton from "../../components/text-button/text-button";
import * as colors from "../../theme/colors";
import ProjectForm from "../../containers/SubmitProjectGallery/ProjectForm";
import Container from "@material-ui/core/Container";
import { ConfirmationModal } from "../../components/modals/projectGallery/confirmationModal";
import Footer from "../../containers/Footer/footer";

import { useTitle } from "../../utils/pageTitles";
import { handleKeyPress } from '../../utils/accessibility';
import { checkIsClubsDomain } from '../../utils/generic';
import { userIs13orOlder } from "../../utils/userUtils";
import * as tokenStorage from '../../tokenStorage';

import * as trackingActions from "../../actions/tracking";
import * as projectGalleryActions from "../../actions/projectGallery";

import shareImg from '../../images/share.svg';
import linkedinImg from '../../images/linkedin.svg';
import copyImg from '../../images/copy.svg';
import facebookImg from '../../images/facebook.svg';
import twitterImg from '../../images/twitter.svg';

import {
  PROJECT_PAGE_VIEW,
  PROJECT_PAGE_LEFT_PAGE,
  PROJECT_PAGE_REPOSITORY_URL_CLICKED,
  PROJECT_PAGE_PROJECT_URL_CLICKED,
  PROJECT_GALLERY_SHARE,
} from "../../constants/trackingEvents";
import linkifyHtml from "linkify-html";

const GalleryProjectTag = {
  PROJECT_TYPE: "PROJECT_TYPE",
  LANGUAGE: "LANGUAGE",
  TOPIC: "TOPIC",
};

const StyledMenu = withStyles({
  paper: {
  },
  list: {
    padding: 0,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    horizontal="true"
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    style={{ marginTop: 49 }}
    {...props}
  />
));

const StyledMenuItem = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 700,
    height: 65,
    display: 'flex',
    justifyContent: 'left',
    textTransform: 'uppercase',
    '&:hover': {
      color: colors.darkThemeGreen,
    },
  },
})(MenuItem);

const openUrlHelper = (url) => {
  window.open(url.indexOf("http") === 0 ? url : "http://" + url, "_blank");
};

let _isMounted = false;
let startViewTime = new Date();

const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paperMainBox: {
    boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.2)",
  },
  container: {
    paddingTop: 20,
    paddingBottom: 20,
    maxWidth: 600,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 370,
    },
    minHeight: "calc(100vh - 135px)",
  },
  successContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    color: colors.grayTheme,
    fontWeight: 700,
    fontSize: 32,
    textTransform: "uppercase",
  },

  subtitle: {
    marginTop: 20,
    color: colors.grayTheme,
    fontWeight: 700,
  },

  backToGallery: {
    padding: "0px",
    color: colors.darkThemeGreen,
    "&:hover": {
      color: colors.grayTheme,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  notFoundContainer: {
    marginTop: 20,
    padding: 10,
  },
  menu: {
    "& .MuiPaper-root": {
      "& ul": {
        display: "flex",
      },
    }
  },
  shareBtnBox: {
    display: 'flex',
  },
  shareImg: {
    width: 28,
    height: 28,
    marginRight: 5,
  },
  linkText: {
    width: 1,
    height: 1,
    opacity: 0,
  },
  shareImgIcon: {
    width: 25,
    height: 25,
  },
  message: {
    display: "block",
  },
  link: {
    color: colors.darkThemeGreen,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
      color: colors.lightThemeGreen,
    },
  },
  projectActions: {
    display: 'flex',
    marginTop: 32,
    justifyContent: 'space-between',
  },
  viewCodeBtn: {
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
      marginRight: 0,
      marginBottom: 10,
    },
  },
  projectViewBox: {
    [theme.breakpoints.down("xs")]: {
      display: "grid",
    },
  },
  shareProjectBox: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  backBtn: {
    "&:focus": {
      outline: `2px auto ${colors.grey400}`,
    },
  },
  reflection: {
    marginTop: theme.spacing(1),
    "white-space": "pre-wrap"
  }
}));

const sources = {
  linkedin: {
    label: 'LINKEDIN',
    link: 'https://www.linkedin.com/sharing/share-offsite/?url={{url}}'
  },
  twitter: {
    label: 'TWITTER',
    link: 'https://twitter.com/share?url={{url}}',
  },
  facebook: {
    label: 'FACEBOOK',
    link: 'https://www.facebook.com/sharer/sharer.php?u={{url}}'
  }
};

const Project = (props) => {
  const {
    user,
    match: {
      params: { projectId },
    },
    getProgramsAndMembersInfo,
    isLoadingMembersList,
    programs,
    contentful,
    history,
    push,
    createTrackingLog,
    showSuccessMessage,
    projectLanguageTags,
    projectTypesTags,
    projectTopicTypeTags,
    getFiltersData,
  } = props;
  
  useTitle("project-details");

  const classes = useStyles();

  const [project, setProject] = useState(null);
  const [projectLoaded, setProjectLoaded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [languageTags, setLanguageTags] = useState([]);
  const [topicTags, setTopicTags] = useState([]);
  const [projectTypeTags, setProjectTypeTags] = useState([]);
  const [labels, setLabels] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [defaultProgramId, setDefaultProgramId] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(null);
  const [projectSent, setProjectSent] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isAbleToNavigateBack, setIsAbleToNavigateBack] = useState(null);
  const [isWarningModalToOpen, setWarningModalToOpen] = useState(false);
  const [initialProjectValues, setInitialProjectValues] = useState(null);
  const [updatedProjectValues, setUpdatedProjectValues] = useState(null);

  const [tags, setTags] = useState({
    projectTypeTags: [],
    languageTags: [],
    topicTags: [],
  });

  const open = Boolean(anchorEl);

  const isClubsDomain = checkIsClubsDomain();

  const getProjectById = (id) => {
    getGalleryProjectById(id)
      .then((res) => {
        if (_isMounted) {
          const project = res.data;
          setProject(project);
          setTags({
            projectTypeTags: project ? project.tags.filter(
              (tag) => tag.tagType === GalleryProjectTag.PROJECT_TYPE
            ) : null,
            languageTags: project ? project.tags.filter(
              (tag) => tag.tagType === GalleryProjectTag.LANGUAGE
            ) : null,
            topicTags: project ? project.tags.filter(
              (tag) => tag.tagType === GalleryProjectTag.TOPIC
            ) : null,
          });

          setProjectLoaded(true);
        }
      })
      .catch(() => {
        setProjectLoaded(true);
      });
  };

  useEffect(() => {
    const hasAccount = !!tokenStorage.getToken();
    if (isClubsDomain && !hasAccount) {
      push('/');
    }
  }, [user]);

  useEffect(() => {
    _isMounted = true;

    //get project by id
    getProjectById(projectId);

    startViewTime = new Date();
    const search = queryString.parse(window.location.search);
    createTrackingLog({
      event: PROJECT_PAGE_VIEW,
      data: search ? JSON.stringify(search) : "",
    });
    return () => {
      createTrackingLog({
        event: PROJECT_PAGE_LEFT_PAGE,
        duration: (new Date() - startViewTime) * 0.001,
      });
      _isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (!user) return;

    if (!projectTopicTypeTags.length || !projectTypesTags.length || !projectLanguageTags.length) {
      getFiltersData();
    }
  }, [user]);

  useEffect(() => {
    if (!projectTopicTypeTags.length || !projectTypesTags.length || !projectLanguageTags.length) return;

    setTopicTags(projectTopicTypeTags);
    setLanguageTags(projectLanguageTags);
    setProjectTypeTags(projectTypesTags);
  }, [projectTopicTypeTags, projectTypesTags, projectLanguageTags]);

  React.useEffect(() => {
    if (!contentful.GlobalComponentQuery) {
      return;
    }
    const {
      hqConfig: { defaultProgram },
      GlobalComponentQuery: { projectGalleryLabels },
    } = contentful;

    setLabels(projectGalleryLabels);
    setDefaultProgramId(defaultProgram);

    if (!project) {
      return;
    }

    const userRoles = user ? user.roles.filter(
      (role) =>
        role.programId &&
        role.programId !== parseInt(defaultProgram) &&
        !!role.program
    ) : [];
    const currProgramRole = userRoles.find(
      (e) => e.programId === project.programId && e.program.active
    );

    if (!!currProgramRole) {
      setSelectedProgram(project.programId);
      getProgramsAndMembersInfo({
        userProgramIds: [project.programId],
      });
    } else {
      const latestOpenProgram = userRoles
        .filter((role) => role.program.active && !role.program.completed)
        .find((e) => e.current);

      if (!!latestOpenProgram) {
        setSelectedProgram(latestOpenProgram.programId);
        getProgramsAndMembersInfo({
          userProgramIds: [latestOpenProgram.programId],
        });
      } else {
        setSelectedProgram(parseInt(defaultProgram));
      }
    }

    if (!!user) {
      setIsOwner(project.submitterId === user.id);
    }
  }, [contentful, project]);

  const onSubmit = (galleryProject, projectImages, tags, teamMembers) => {
    const images = projectImages.map((imgUrl, index) => ({
      url: imgUrl,
      isPrimary: index === 0,
    }));

    updateProject(project.id, {
      project: { ...galleryProject, archivedAt: null },
      images,
      tags,
      teamMembers,
    })
      .then((result) => {
        if (result.data) {
          _isMounted = true;
          getProjectById(project.id.toString());
          setIsEdit(false);
        }
      })
      .catch(() => { });
  };

  const handleMenu = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) setAnchorEl(null);
  };

  const renderImageGallery = () => {
    const allImages = project && project.images ? project.images
      .sort((a, b) => {
        if (a.isPrimary) return -1;
        if (b.isPrimary) return 1;
        return 0;
      })
      .map((img) => img.url) : [];

    const selectedImageIndex = selectedImage || 0;

    return (
      <div className="carousel">
        <div className="main-image">
          <img
            src={allImages[selectedImageIndex]}
            alt={`mainimage-${selectedImageIndex}`}
          />
        </div>

        {allImages.length > 1 && (
          <div className="images-catalog">
            {allImages.map((imageSrc, index) => (
              <div
                className="catalog-thumbnail"
                key={index}
                tabIndex={0}
                style={{ backgroundImage: `url('${imageSrc}')` }}
                onClick={() => setSelectedImage(index)}
                role="button"
                onKeyPress={handleKeyPress(() => setSelectedImage(index))}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div>
        {!!tags.languageTags.length && (
          <div className="tags-list">
            <Typography className="tag-label" align="left" color="primary">
              Languages:
            </Typography>
            {tags.languageTags.map((tag, index) => (
              <Chip label={tag.name} key={index} className="tag round" />
            ))}
          </div>
        )}

        {!!tags.topicTags.length && (
          <div className="tags-list">
            <Typography className="tag-label" align="left" color="primary">
              Topics:
            </Typography>
            {tags.topicTags.map((tag, index) => (
              <Chip label={tag.name} key={index} className="tag" />
            ))}
          </div>
        )}
      </div>
    );
  };

  const openLinkInNewWindow = (shareLink) => {
    window.open(shareLink, "_blank");
  }

  const renderProjectTypes = () => {
    return (
      <span className="type-tags">
        {!!tags.projectTypeTags.length &&
          tags.projectTypeTags.map((tag, index) => (
            <Chip label={tag.name} key={index} className="tag" />
          ))}
      </span>
    );
  };

  const renderNavigationButtons = () => {
    const { projectUrl, repositoryUrl, id, name, description, archivedAt, approvedAt, updatedAt, submitter } = project;
    const submitterIs13OrOlder = userIs13orOlder(submitter);
    const showShareButtons = !isClubsDomain && submitterIs13OrOlder && (!updatedAt || updatedAt < approvedAt) && (!!approvedAt && !archivedAt);

    const currentProjectLink = window.location.href;

    return (
      <div className={classes.projectActions}>
        <Box className={classes.shareProjectBox}>
          {showShareButtons && <TextCustomButton
            onClick={handleMenu}
            label={<>
              <Box className={classes.shareBtnBox}>
                {labels && <img src={shareImg} className={classes.shareImg} alt="share" />}
              </Box>
              <Box>
                Share Project
              </Box>
            </>}
          />}
          <StyledMenu
            id="shareMenu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            className={classes.menu}
          >
            <StyledMenuItem
              tabIndex="0"
              onClick={() => {
                createTrackingLog({
                  event: PROJECT_GALLERY_SHARE,
                  data: JSON.stringify({
                    projectId: id.toString(),
                    source: sources.twitter.label,
                  }),
                });

                const shareLink = sources.twitter.link
                  .replace("{{url}}", currentProjectLink)
                  .replace("{{title}}", `${name} ${description}`);

                openLinkInNewWindow(shareLink);
              }} >
              <img src={twitterImg} className={classes.shareImgIcon} alt="Twitter" />
            </StyledMenuItem>
            <StyledMenuItem
              tabIndex="0"
              onClick={() => {
                createTrackingLog({
                  event: PROJECT_GALLERY_SHARE,
                  data: JSON.stringify({
                    projectId: id,
                    source: sources.facebook.label,
                  }),
                });

                const shareLink = sources.facebook.link
                  .replace("{{url}}", currentProjectLink)
                  .replace("{{title}}", `${name} ${description}`);

                openLinkInNewWindow(shareLink);
              }}>
              <img src={facebookImg} className={classes.shareImgIcon} alt="Facebook" />
            </StyledMenuItem>
            <StyledMenuItem
              tabIndex="0"
              onClick={() => {
                createTrackingLog({
                  event: PROJECT_GALLERY_SHARE,
                  data: JSON.stringify({
                    projectId: id,
                    source: sources.linkedin.label,
                  }),
                });

                const shareLink = sources.linkedin.link.replace("{{url}}", currentProjectLink);
                openLinkInNewWindow(shareLink);
              }} >
              <img src={linkedinImg} className={classes.shareImgIcon} alt="Linkedin" />
            </StyledMenuItem>
            <StyledMenuItem
              tabIndex="0"
              onClick={() => {
                navigator.clipboard.writeText(currentProjectLink);
                showSuccessMessage('Link copied!');
              }}
            >
              <img src={copyImg} className={classes.shareImgIcon} alt="Copy" />
            </StyledMenuItem>
          </StyledMenu>
        </Box>
        <Box className={classes.projectViewBox}>
          {repositoryUrl && (
            <CustomButton
              mode={"secondary"}
              className={classes.viewCodeBtn}
              onClick={() => {
                openUrlHelper(repositoryUrl);
                createTrackingLog({
                  event: PROJECT_PAGE_REPOSITORY_URL_CLICKED,
                  data: repositoryUrl,
                });
              }}
              style={{ height: 42 }}
              label={labels.singleProjectPageOpenRepositoryLabel}
            />
          )}
          {projectUrl && (
            <CustomButton
              mode={"primary"}
              style={{ height: 42 }}
              onClick={() => {
                openUrlHelper(projectUrl);
                createTrackingLog({
                  event: PROJECT_PAGE_PROJECT_URL_CLICKED,
                  data: projectUrl,
                });
              }}
              label={labels.singleProjectPageOpenProjectLabel}
            />
          )}
        </Box>
      </div>
    );
  };

  const getTeamMembers = (project) => {
    const membersList = "";

    if (!project) {
      return membersList;
    }

    if (project.teamMembers && !!project.teamMembers.length) {
      return project.teamMembers.map((e) => e.firstName.trim()).join(", ");
    }

    if (project && project.oldTeamMembers) {
      return JSON.parse(project.oldTeamMembers).join(", ");
    }

    return membersList;
  };

  const prevPath = !!history.location.state?.prevPath ? history.location.state.prevPath : '/project-gallery';
  const previousHistoryLabel = (prevPath) => {
    switch (true) {
      case (/\progress/).test(prevPath):
        return 'progress';
      case (/\profile/).test(prevPath):
        return 'profile';
      case (/\project-gallery/).test(prevPath):
        return 'project gallery';
    };
  };

  const navigateBack = () => {
    if (
      !!history.location.state &&
      !!history.location.state.prevPath
    ) {
      push(history.location.state.prevPath);
    } else {
      push("/project-gallery");
    }
  }

  useEffect(() => {
    if (isAbleToNavigateBack) {
      navigateBack();
    }
  }, [isAbleToNavigateBack]);

  const updateIsAbleToNavigateBack = (value) => {
    setIsAbleToNavigateBack(value);
    if (!isAbleToNavigateBack) {
      setWarningModalToOpen(false);
    }
  }

  const updateInitialProjectValues = (values) => {
    setInitialProjectValues(values);
  }

  const updateProjectValues = (values) => {
    setUpdatedProjectValues(values);
  }

  const hasProjectDataChanged = () => {
    return JSON.stringify(initialProjectValues) !== JSON.stringify(updatedProjectValues);
  };

  return (
    <>
      {!isEdit && !projectSent && (
        <div className="project-page">
          {project && project.id && (
            <Paper className="card" elevation={4}>
              {renderImageGallery()}

              <div className="card-content">
                <Box className={classes.titleBox}>
                  <Typography
                    align="left"
                    component="h1"
                    color="primary"
                    className="title"
                  >
                    {project.name}
                    {renderProjectTypes()}
                  </Typography>
                  {!!user && isOwner && (
                    <CustomButton
                      mode="secondary"
                      endIcon={<ArrowRightAltIcon />}
                      onClick={() => {
                        setOpenConfirm(true);
                      }}
                      label={"Edit"}
                    />
                  )}
                </Box>
                <Typography align="left" component="h3" color="primary">
                  {project.program.name}
                </Typography>
                <Typography
                  component="p"
                  align="left"
                  className="members"
                  color="primary"
                >
                  {getTeamMembers(project)}
                </Typography>
                <Typography component="p" className="description" align="left">
                  {project.description}
                </Typography>
                {project.clubChallenge && project.reflection && (
                    <Box>
                      <Typography align="left" component="p" color="primary" className={classes.subtitle}>
                        Project Reflection
                      </Typography>
                      <Typography component="p" className={classes.reflection} align="left" dangerouslySetInnerHTML={createMarkup(linkifyHtml(project.reflection, { className: classes.link, target: "_blank" }))}>
                      </Typography>
                    </Box>)}
                {renderTags()}
                {renderNavigationButtons()}
              </div>
            </Paper>
          )}

          {project && !!project.isNotAvailable && projectLoaded && (
            <Paper className={classes.notFoundContainer} elevation={4}>
              <Typography component="p" className="description" align="left">
                We’re sorry. It looks like that Project is not currently
                available. Please return to the Project Gallery to explore
                current Projects
              </Typography>
            </Paper>
          )}

          {(project && !!project.isArchived) && (
            <Paper className={classes.notFoundContainer} elevation={4}>
              <Typography component="p" className="message" align="left">
                This project is no longer available. Check out all of our projects
                <Link
                  className={classes.link}
                  onClick={() => {
                    push("/project-gallery");
                  }}
                > here</Link>.
              </Typography>
            </Paper>
          )}

          {(project && !!project.isPending) && (
            <Paper className={classes.notFoundContainer} elevation={4}>
              <Typography component="p" className="message" align="left">
                This project is pending review. Check out all of our published projects
                <Link
                  className={classes.link}
                  onClick={() => {
                    push("/project-gallery");
                  }}
                > here</Link>.
              </Typography>
            </Paper>
          )}

          <div className="action-bar">
            <Link
              className="project-link"
              to={{
                pathname: `${!!history.location.state &&
                  !!history.location.state.prevPath
                  ?history.location.state.prevPath :  "/project-gallery"}`,
                state: {
                  activeTab: history?.location?.state?.activeTab,
                },
              }}
              tabIndex={-1}
            >
              <TextCustomButton
                style={{ color: colors.darkThemeBlue }}
                startIcon={<KeyboardArrowLeftIcon />}
                label={
                  !!history.location.state && !!history.location.state.label
                    ? `Back to ${previousHistoryLabel(prevPath)}`
                    : "Back"
                }
                sel="view-full-gallery"
              />
            </Link>
          </div>
          <ConfirmationModal
            isOpen={!!openConfirm}
            text={labels.submissionFormEditMessage || ""}
            onClose={() => {
              setOpenConfirm(false);
              setIsEdit(false);
            }}
            onSave={() => {
              setOpenConfirm(false);
              setIsEdit(true);
            }}
          />
        </div>
      )}
      {isEdit && !projectSent && (
        <Container className={classes.container}>
          <TextCustomButton
            className={classes.backToGallery}
            mode="secondary"
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={() => {
              const projectDataChanges = hasProjectDataChanged();

              if (projectDataChanges) {
                setWarningModalToOpen(true);
                return;
              }

              navigateBack();
            }}
            label={
              !!history.location.state && !!history.location.state.label
                ? `Back to ${previousHistoryLabel(prevPath)}`
                : "Back"
            }
          />
          <Typography className={classes.title}>Submit Your Project</Typography>
          {project && (
            <>
              <Paper className={classes.paperMainBox}>
                <ProjectForm
                  project={project}
                  projectTags={tags}
                  curriculumSet={project.curriculumSet}
                  user={user}
                  getProgramsAndMembersInfo={getProgramsAndMembersInfo}
                  push={push}
                  isLoadingMembersList={isLoadingMembersList}
                  programs={programs}
                  contentful={contentful}
                  createTrackingLog={createTrackingLog}
                  projectTypeTags={projectTypeTags}
                  languageTags={languageTags}
                  topicTags={topicTags}
                  onSubmit={onSubmit}
                  defaultProgramId={defaultProgramId}
                  selectedProgram={selectedProgram}
                  setProjectSent={setProjectSent}
                  setInitialProjectValues={updateInitialProjectValues}
                  setUpdatedProjectValues={updateProjectValues}
                  setIsAbleToNavigateBack={updateIsAbleToNavigateBack}
                  isWarningModalToOpen={isWarningModalToOpen}
                />
              </Paper>
            </>
          )}
        </Container>
      )}
      {projectSent && (
        <Container className={classes.container}>
          <Container className={classes.successContainer}>
            <Typography
              align="center"
              variant="subheading"
              sel="thanks-for-submission"
            >
              Thanks for your submission! You’ll receive an email when your
              project has been reviewed and published to the Project Gallery.
            </Typography>
            <TextCustomButton
              mode="secondary"
              className={classes.backBtn}
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={() => {
                if (
                  !!history.location.state &&
                  !!history.location.state.prevPath
                ) {
                  props.push(history.location.state.prevPath);
                } else {
                  props.push("/project-gallery");
                }
              }}
              label={
                !!history.location.state && !!history.location.state.label
                  ? `Back to ${previousHistoryLabel(prevPath)}`
                  : "Back"
              }
            />
          </Container>
        </Container>
      )}
      <Footer />
    </>
  );
};

export default connectTo(
  (state) => ({
    user: state.auth.user,
    auth: state.auth,
    contentful: state.contentful,
    ...state.calendar,
    ...state.projectGallery,
  }),
  {
    ...calendarActions,
    ...trackingActions,
    ...notificationActions,
    ...projectGalleryActions,
    push: pushToUrl,
  },
  Project
);
