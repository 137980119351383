import {axiosInstance, noCacheHeaders} from "./axiosInstance";
import {getAuthHeader} from "../tokenStorage";

export const getAnnouncements = async () => {
  return axiosInstance.get(
    `/blapi/announcements/get`,
    {
      headers: {
        ...getAuthHeader(),
        ...noCacheHeaders,
      }
    }
  );
};