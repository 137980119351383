import { call, put } from "redux-saga/effects";

import { receivedAnnouncements } from "../actions/announcements";
import * as announcementsApi from "../api/announcements";

export function* getAnnouncements({payload}) {
  try {
    let { data } = yield call(announcementsApi.getAnnouncements, payload);
    yield put(receivedAnnouncements(data));
  } catch (e) {
    // TODO: throw generic error
    console.log("TODO: handle error here");
    console.log(e);
  }
}
